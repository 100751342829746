<template>
  <a-modal v-model="addPlaceView" :title="this.names" @onCancel="handleCancel" @ok="handleSubmit">
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <a-form-model-item has-feedback label="广告主名称" v-if="this.isAdd === 1">
        {{ query.advertiserName }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="代理商名称" v-if="this.isAdd === 2">
        {{ query.agentName }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="广告主名称" v-if="this.isAdd === 1">
        {{ query.advertiserName }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="广告主账号" v-if="this.isAdd === 1">
        {{ query.account }}
      </a-form-model-item>
      <!--  <a-form-model-item has-feedback label="密码" v-if="this.tps==1">
        <a-input v-model.trim="query.password" autocomplete="off" placeholder="请输入密码" />
      </a-form-model-item> -->
      <a-form-model-item has-feedback label="公司全称" v-if="this.isAdd === 1">
        {{ query.advertiserCompany }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="公司全称" v-if="this.isAdd === 2">
        {{ query.agentCompany }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="联系电话">
        {{ query.contactPhone }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="所属代理" v-if="this.isAdd === 1">
        {{ query.agentName }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="客户经理">
        {{ query.username }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="所属行业" v-if="this.isAdd === 1">
        {{ query.tradeName }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="广告分类" v-if="this.isAdd === 1">
        {{ query.advertiserCategory }}
      </a-form-model-item>
      <a-form-model-item has-feedback label="图标">
        <!-- action="uploadUrl" -->
        <img v-if="imagesUrl" :src="imagesUrl" alt="图标" style="width: 120px" />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tps: 1,
      datts: [],
      defectSearchForm: {},
      imagesUrl: '',
      url: '...',
      fileList: [],
      sysAgent: [],
      businessUser: [],
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      loading: false,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      query: {},
      appList: [],
      positionList: [],
      templateList: [],
      names: ''
    }
  },
  props: {
    isAdd: {
      type: Number,
      default: 0
    },
    visible: {
      type: [Boolean, Object],
      default: false
    },
    appId: {
      default: '',
      type: String
    },
    placeView: {
      type: [Boolean, Object],
      default: () => ({})
    }
  },
  watch: {
    placeView: {
      handler (val) {
        if (this.isAdd === 1) {
          this.names = '查看广告主'
          this.query = JSON.parse(JSON.stringify(val))
          this.query.sysAgent = val.sysAgent * 1
          this.query.businessUser = val.businessUser * 1
          if (val.advertiserCategory === 1) {
            this.query.advertiserCategory = '品牌'
          } else {
            this.query.advertiserCategory = '效果'
          }
          // this.query.sysAgent = val.sysAgent*1
          const advertiserIndustry = []
          advertiserIndustry.push(val.pid * 1)
          advertiserIndustry.push(val.advertiserIndustry * 1)
          this.query.advertiserIndustrys = advertiserIndustry
          this.imagesUrl = process.env.VUE_APP_API_BASE_URL + val.businessLicense
        } else if (this.isAdd === 2) {
          this.names = '查看代理商'
          this.query = JSON.parse(JSON.stringify(val))
          this.imagesUrl = process.env.VUE_APP_API_BASE_URL + val.businessLicense
        }
      },
      deep: true,
      immediate: true
    },
    appId: {
      handler (val) {
        if (val) {
          this.query.appId = val
        }
      },
      immediate: true
    }
  },
  mounted () {},
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addPlaceView: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.addPlaceView = false
    },
    handleSubmit () {
      this.addPlaceView = false
    }
  }
}
</script>

<style></style>
